import News1 from "../../assets/news-1.jpg"

export const NewsData = [
    {
        s: "NEWS & EVENTS",
        s2: "OUTSYSTEMS",
        url: "/",
        img: News1,
        title: "Ranosys to exhibit at OutSystems NextStep Exec - Singapore 2023",
        description: 'Ranosys, an OutSystems Partner and a leading Digital Transformation Agency, will be exhibiting at OutSystems NextStep Exec - Singapore 2023 on 10 October 2023....',
        date: "05 Oct 2023"
    },
    {
        s: "NEWS & EVENTS",
        s2: "OUTSYSTEMS",
        url: "/",
        img: News1,
        title: "Ranosys to exhibit at OutSystems NextStep Exec - Singapore 2023",
        description: 'Ranosys, an OutSystems Partner and a leading Digital Transformation Agency, will be exhibiting at OutSystems NextStep Exec - Singapore 2023 on 10 October 2023....',
        date: "05 Oct 2023"
    },
    {
        s: "NEWS & EVENTS",
        s2: "OUTSYSTEMS",
        url: "/",
        img: News1,
        title: "Ranosys to exhibit at OutSystems NextStep Exec - Singapore 2023",
        description: 'Ranosys, an OutSystems Partner and a leading Digital Transformation Agency, will be exhibiting at OutSystems NextStep Exec - Singapore 2023 on 10 October 2023....',
        date: "05 Oct 2023"
    },
    {
        s: "NEWS & EVENTS",
        s2: "OUTSYSTEMS",
        url: "/",
        img: News1,
        title: "Ranosys to exhibit at OutSystems NextStep Exec - Singapore 2023",
        description: 'Ranosys, an OutSystems Partner and a leading Digital Transformation Agency, will be exhibiting at OutSystems NextStep Exec - Singapore 2023 on 10 October 2023....',
        date: "05 Oct 2023"
    },
    {
        s: "NEWS & EVENTS",
        s2: "OUTSYSTEMS",
        url: "/",
        img: News1,
        title: "Ranosys to exhibit at OutSystems NextStep Exec - Singapore 2023",
        description: 'Ranosys, an OutSystems Partner and a leading Digital Transformation Agency, will be exhibiting at OutSystems NextStep Exec - Singapore 2023 on 10 October 2023....',
        date: "05 Oct 2023"
    },
    {
        s: "NEWS & EVENTS",
        s2: "OUTSYSTEMS",
        url: "/",
        img: News1,
        title: "Ranosys to exhibit at OutSystems NextStep Exec - Singapore 2023",
        description: 'Ranosys, an OutSystems Partner and a leading Digital Transformation Agency, will be exhibiting at OutSystems NextStep Exec - Singapore 2023 on 10 October 2023....',
        date: "05 Oct 2023"
    },
    {
        s: "NEWS & EVENTS",
        s2: "OUTSYSTEMS",
        url: "/",
        img: News1,
        title: "Ranosys to exhibit at OutSystems NextStep Exec - Singapore 2023",
        description: 'Ranosys, an OutSystems Partner and a leading Digital Transformation Agency, will be exhibiting at OutSystems NextStep Exec - Singapore 2023 on 10 October 2023....',
        date: "05 Oct 2023"
    },
    {
        s: "NEWS & EVENTS",
        s2: "OUTSYSTEMS",
        url: "/",
        img: News1,
        title: "Ranosys to exhibit at OutSystems NextStep Exec - Singapore 2023",
        description: 'Ranosys, an OutSystems Partner and a leading Digital Transformation Agency, will be exhibiting at OutSystems NextStep Exec - Singapore 2023 on 10 October 2023....',
        date: "05 Oct 2023"
    },
]